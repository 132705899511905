<template>
  <div>
      <v-select
          :value="value"
          :options="selectClients"
          :filterable="false"
          :placeholder="$t('Buscar por empresa')"
          label="name"
          @open="onOpen"
          @close="onClose"
          @search="(query) => (search = query)"
          @input="(val) => $emit('input', val)"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            cargando mas opciones...
          </li>
        </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import clientsApi from '@/api/clients-api'

export default {
  name: 'SelectClients',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
  },
  data() {
    return {
      observer: null,
      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: '',
      selectClients: [],
      selected: '',
      loadingMoreOptions: false, // Indicador de carga de más opciones
    }
  },
  computed: {
    ...mapGetters('clients', {
      clients: 'getListClients',
    }),
    hasNextPage() {
      return this.lastPage > this.currentPage
    },
  },
  watch: {
    async search() {
      this.currentPage = 1
      this.selectClients = []
      await this.chargeData(true)
    },
  },
  async mounted() {
    await this.chargeData() // Ahora el método chargeData está definido antes de que se llame aquí
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    ...mapActions({
      getListClients: 'clients/getListClients',
    }),
    async chargeData(ifSearch = false) {
      await clientsApi.list(this.currentPage, this.pageLength, '', '', true, this.search)
        .then(
          response => {
            this.selectClients = (ifSearch) ? response.data.data : this.selectClients.concat(response.data.data)
            this.lastPage = response.data.meta.last_page
          },
          () => {
            console.log('ERROR')
          },
        )
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting && this.hasNextPage && !this.loadingMoreOptions) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.currentPage += 1
        this.loadingMoreOptions = true // Activar indicador de carga
        await this.chargeData()
        ul.scrollTop = scrollTop
        this.loadingMoreOptions = false // Desactivar indicador de carga después de cargar más opciones
      }
    },
  },
}
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
